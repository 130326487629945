<template>
    <div style="height: 450px;">
        <el-row style="width:100%;height: 60%;">
        <el-col :span="8">
            <el-card style="width:100%;height: 100%;">
                <el-image :src="url" fit="fill"></el-image>
            </el-card>
        
        </el-col>

        <el-col  v-if="!show_calendar" :span="16" style="padding-left: 10px;height: 100%;">
            <el-card style="width: 100%;height: 100%;">
                <div slot="header" class="clearfix">
                    <span>用户信息</span>
                </div>
                <el-row style="height: 30px;">
                    <el-col :span="12">用户名</el-col>
                    <el-col :span="12">{{info.name}}</el-col>
                </el-row>
                <el-row style="height: 30px;">
                    <el-col :span="12">邮箱</el-col>
                    <el-col :span="12">{{info.email}}</el-col>
                </el-row>
                <el-row style="height: 30px;">
                    <el-col :span="12">余额</el-col>
                    <el-col :span="12">{{info.money}}</el-col>
                </el-row>
                <el-row style="height: 30px;">
                    <el-col :span="12">生日</el-col>
                    <el-col :span="12">{{info.birthday}}</el-col>
                </el-row>
                    
            </el-card>
        </el-col>

        <el-col  v-if="show_calendar" :span="16" style="padding-left: 10px;">
            <el-card style="width: 100%;">
                <el-calendar v-model="value"></el-calendar>
            </el-card>
        </el-col>
    </el-row>
    </div>
  
 
</template>

<script>
//import user from '@/api/user' 
export default {
    name:'FrontView',
    props:['show_calendar'],
    data(){
        return{
            
            url:'https://img2.imgtp.com/2024/04/25/TdDBfkDa.png',
            info:''
        }
    },
    mounted(){
        
    }
}
</script>

<style>

</style>