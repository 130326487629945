<template>
  <div>
    <el-container style=" border: 1px solid #eee;text-align: center;" direction="vertical">
        <el-main style="height: 800px;">
          <el-row>
            <el-col :offset="5" :span="14">
              <div style="background-color: rgb(243, 243, 243);" class="bgh">
                <!--内容主体-->
                <el-row>
                  <h3>注册新用户</h3>
                </el-row>
                
                <el-row>
                  <div class="mybox">
                    <!-- 左半 -->
                      <el-col :offset="1" :span="10">
                        <el-form :model="formInline" :rules="rules" ref="formInline" class="demo-form-inline" style="width: 100%;">
                            <el-form-item prop="name">
                                <el-input v-model="formInline.name" placeholder="用户名"  maxlength="10"></el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input v-model="formInline.password" placeholder="密码" show-password></el-input>
                            </el-form-item>
                            
                            <el-form-item prop="email">
                                <el-input v-model="formInline.email" placeholder="电子邮箱"></el-input>
                            </el-form-item>
                          
                            <el-form-item prop="birthday">
                                <el-date-picker
                                v-model="formInline.birthday"
                                type="date"
                                placeholder="年/月/日"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%;">
                            </el-date-picker>
                            </el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="2">
                        <div class="yc">
                          <div class="el-divider el-divider--vertical" style="height: 13em;"></div>
                        </div>
                        
                      </el-col>
                      <!-- 右半 -->
                      <el-col :span="11">
                        <p class="tip_upload" v-if="imageUrl==''">注册新用户</p>
                        <!-- 上传头像 -->
                        <el-upload
                          class="avatar-uploader"
                          action="#"
                          :show-file-list="false"
                          :auto-upload=false
                          :on-change="handleImg">
                          <img v-if="imageUrl" :src="imageUrl" class="avatar">
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                      </el-col>
                </div>
                   
                </el-row>


                <el-row style="background-color: rgb(80, 80, 80);" >
                  <div class="mybox">
                       <!-- 左半 -->
                   <el-col :offset="1" :span="10">
                    <span style="line-height: 50px;" @click="submit('formInline')">注册用户</span>
                  </el-col>
           <!-- 右半 -->
           <el-col :offset="2" :span="11">
               <span style="line-height: 50px;" @click="tologin">返回登录</span>
           </el-col>
                </div>
                  
                </el-row>
                
                
                <!--主体结束-->
              </div>
            </el-col>
          </el-row>

          <!-- vueCropper 剪裁图片实现-->
          <el-dialog title="图片剪裁" :visible.sync="dialogVisible" append-to-body>
            <div class="cropper-content">
              <div class="cropper" style="text-align:center">
              <vueCropper
                  ref="cropper"
                  :img="option.img"
                  :outputSize="option.size"
                  :outputType="option.outputType"
                  :info="true"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :centerBox="option.centerBox"
                  :infoTrue="option.infoTrue"
                  :fixedBox="option.fixedBox"
                ></vueCropper>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
            </div>
          </el-dialog>
        </el-main>       
    </el-container>
  </div>
</template>

<script>
//import UploadCop from 'utils/UploadCop.vue'
import user from '@/api/user' 
export default {
name: 'RegistPage',

data() {
  var checkpwd = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('密码不能为空'));
        }
        callback();
      };
  var checkname = (rule, value, callback) => {
        if (!value) {
          callback(new Error('用户名不能为空'));
        }
        if(value.length<3){
          callback(new Error('用户名长度不能小于5'));
        }
        callback();
      };
  var checkEmail = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('email不能为空'));
        }
        if(value.length<5){
          return callback(new Error('email长度不能小于5'));
        }
        callback();
      };
    var checkYear = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('出生年月不能为空'));
      }
      if(value.length<5){
        return callback(new Error('错误'));
      }
      callback();
    };
      return {
        dialogVisible: false,
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址,blob临时url地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
       // 防止重复提交
       loading: false,
      //表单
        formInline: {
          name: '',
          password: '',
          email: '',
          birthday: '',
          avatar:''
        },

        imageUrl: '',
        rules: {
            email: [
                { validator: checkEmail, trigger: 'blur' }
            ],
            birthday: [
                { validator: checkYear, trigger: 'blur' }
            ],
            name: [
                { validator: checkname, trigger: 'blur' }
            ],
            password: [
                { validator: checkpwd, trigger: 'blur' }
            ]
        }
      }
    },
    methods: {
      handleImg(file,fileList) {
      //限制图片大小
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 2MB!')
        return false
      }
        console.log(file,fileList);
        this.imageUrl = URL.createObjectURL(file.raw);//将图片转为临时url
        this.formInline.avatar = file.raw; //拿到图片的二进制流

        this.$nextTick(() => {
        this.option.img = this.imageUrl
        this.dialogVisible = true
      })
        // console.log('1',file.url)
        // console.log('2',this.imageUrl)
        // console.log('3',this.formInline.avatar)
      },
      finish() {
      this.$refs.cropper.getCropBlob((data) => {
        //返回的是Blob数据，但后端的MultiPartFile好像也能接收？
        console.log(data)
        this.formInline.avatar = data
        this.imageUrl = URL.createObjectURL(data)
  
        this.loading = true
        this.dialogVisible = false
        //console.log(data)
      })
    },
      check(formName){
        var flag=true
        this.$refs[formName].validate((valid,msg) => {
          console.log(valid,msg)
          if (valid) {
            if(this.formInline.avatar == ''){
              console.log('avatar is null')
              flag = false
              return false
            }
            flag = true
            return true
          } else {
            flag = false
            return false
          }
        });
        console.log("flag",flag)
        return flag
      },
     
      submit(formName){
        console.log('点击一次')
        var res = this.check(formName)
        console.log("结果",res)
        if(this.check(formName)==true){
          console.log('submit!');
          //构建表单
          const formData = new FormData();
          for (var key in this.formInline) {
            formData.append(key, this.formInline[key]);
          }
          //发起post请求
          user.register(formData).then(res => {
            console.log(res)
            //注册成功
            if(res.data.code == 200){
              this.$message({
                message: '注册成功',
                type: 'success'
              })
              this.$router.push('/')
            }
            //注册失败
            else{
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          });
        }
        else{
          console.log('error submit!!');
          this.$message({
          message: '请填写完整信息',
          type: 'error'
          });
        }
      },
      tologin(){
          this.$router.push('/')
      }
    }
}

</script>

<style scoped>

.cropper {
        width: auto;
        height: 300px;
    }
.cropper-content {
        width: auto;
        height: 300px;
}

@media (max-width: 800px) {
    .mybox {
        display: flex;
        flex-direction: column;

        justify-content: space-between;
        align-items: center;
    }
    .bgh{
      height:600px;
    }
    .yc {
        display: none;
    }
}

/* 响应式布局 */
@media (min-width: 800px) {
    .mybox {
      display: flex;
        justify-content: space-between;

        /*背景色为浅灰色*/
        text-align: center;
    }
    .tip_upload{
      margin-top: 0;
      margin-bottom: 0;
    }
    .bgh{
      height:400px;
    }
    .yc {
        
    }
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
    
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>