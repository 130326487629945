import Vue from 'vue'
import VueRouter from 'vue-router'
import RegistPage from '../components/RegistPage.vue'
import LoginPage from '../components/LoginPage.vue'
import UpdatePwd from '../components/UpdatePwd.vue'
import HomeView from '../components/HomeView.vue'
import FrontView from '../components/FrontView.vue'
import UserView from '../components/UserView.vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/login',name:'login',component:LoginPage
    },
    {
        //path: '/',name:'login',component:LoginPage
        //重定向
        path: '/', redirect: '/home/front'
    },
    {
        path: '/register',name:'regist',component:RegistPage
    },
    {
        path: '/updatepwd',name:'updatepwd',component:UpdatePwd
    },
    {
        path: '/home',name:'home',component:HomeView,
        meta: {
            isAuth: true
        },
        children:[
            {
                path: 'front',
                component: FrontView
            },
            {
                path: 'user',
                component: UserView
            }
        ]
    
    }
]

const router = new VueRouter({
    routes
})

// import user from '@/api/user' 
// router.beforeEach((to, from, next) => {
//     const token = localStorage.getItem('token');
//     const isLogin = !!token;//有无token
//     console.log("进入检查")
//     if (to.matched.some(itme => itme.meta.isAuth)) {   //当前路由是否需要权限控制
//         console.log("需要权限控制")
//         if (isLogin) { //本地有token
//             //向后端发送token验证
//            user.verify(token).then(res => {
//                     if (res.data.code == '200') {
//                         if (to.name == 'login') {//已登录，但是要去登录页，重定向到首页
//                             console.log("已登录，但是要去登录页，重定向到首页")
//                             router.push('/home/front',()=>{}, ()=>{})
//                             return;
//                         } else {//放行
//                             console.log("已登录，放行")
//                             next();
//                             return;
//                         }
//                     }
//                     console.log(res.data);
//                     if (res.data.code == '400') {//无效token
//                         console.log("无效token,去登录页")
//                         localStorage.removeItem('token');
//                         localStorage.removeItem('uid');
//                         localStorage.removeItem('name');
//                         router.push('/',()=>{}, ()=>{});
//                         return;
//                     }
                    
          

//                 })

//         }
//         if (!isLogin && to.name === 'login') {  //未登录，但是要去登录页
//             console.log("未登录，但是要去登录页")
//             next()
//             return
//         }
//         console.log(to.name);
//         if (!isLogin && to.name !== 'login') { //未登录，去的也不是登录页，重定向到登录页
//             console.log("未登录，去的也不是登录页，重定向到登录页")
//             router.push('/',()=>{}, ()=>{})
//             return
//         }
//     }
//     else {//不需要权限控制，直接放行
//         console.log("不需要权限控制，直接放行")
//         next();
//         return
//     }
// })

export default router