<template>
    <div>
      <el-container style=" border: 1px solid #eee;text-align: center;" direction="vertical">
          <el-main style="height: 800px;">
            <el-row>
              <el-col :offset="5" :span="14">
                <div style="background-color: rgb(243, 243, 243);" class="bgh">
                  <!--内容主体-->
                  <el-row>
                    <h3>登录</h3>
                  </el-row>
                  
                  <el-row>
                    <div class="mybox">
                      <!-- 左半 -->
                        <el-col :offset="1" :span="10">
                          <el-form :model="formInline" class="demo-form-inline" style="width: 100%;">
                              <el-form-item>
                                <el-button type="primary" style="width: 100%;">QQ登录</el-button>
                              </el-form-item>
                              <el-form-item>
                                <el-button type="success" style="width: 100%;">微信登录</el-button>
                              </el-form-item>
                              
                              <el-form-item>
                                <el-button type="info" style="width: 100%;">支付宝登录</el-button>
                              </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="2">
                          <div class="yc">
                            <div class="el-divider el-divider--vertical" style="height: 13em;"></div>
                          </div>
                          
                        </el-col>
                        <!-- 右半 -->
                        <el-col :span="11">
                            <div style="margin-right: 20px">
                                <el-form :model="formInline" class="demo-form-inline" style="width: 100%">
                            <el-form-item>
                                <el-input v-model="formInline.username" placeholder="用户名"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="formInline.password" placeholder="密码" show-password></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="success" style="width: 100%;" @click="login">登录</el-button>
                              </el-form-item>
                            
                        </el-form>
                            </div>
                            
                        </el-col>
                  </div>
                     
                  </el-row>
  
  
                  <el-row style="background-color: rgb(80, 80, 80);">
                     <!-- 左半 -->
                     <el-col :offset="1" :span="10">
               
                        <span style="line-height: 50px;" @click="toregist">注册用户</span>
                     
                       
                      </el-col>
                      <!-- 右半 -->
                      <el-col :offset="2" :span="11">
                     
                          <span style="line-height: 50px;"  @click="to_updatepwd">修改密码</span>
                
                      </el-col>
                  </el-row>
                  
                  
                  <!--主体结束-->
                </div>
              </el-col>
            </el-row>
          </el-main>       
      </el-container>
    </div>
  </template>
  
  <script>
  //import UploadCop from 'utils/UploadCop.vue'
  import user from '@/api/user' 
  export default {
  name: 'LoginPage',
  
  data() {
        return {
          formInline: {
            username: '',
            password: '',
          },

        }
      },
      methods: {
        toregist(){
            this.$router.push('/register')
        },
        to_updatepwd(){
            this.$router.push('/updatepwd')
        },
        tohome(){
            this.$router.push('/home/front')
        },
        check(){
          if(this.formInline.username == ''){
            this.$message({
              message: '用户名不能为空',
              type: 'warning'
            })
            return false
          }
          if(this.formInline.password == ''){
            this.$message({
              message: '密码不能为空',
              type: 'warning'
            })
            return false
          }
          return true
        },
        login(){
          if(!this.check()){
            return
          }

          let data = {
            name: this.formInline.username,
            password: this.formInline.password
          }
         
          user.login(data).then(res => {
            console.log(res)
            if(res.data.code == 200){
              this.$message({
                message: '登录成功',
                type: 'success'
              })

              localStorage.setItem('token', res.data.data)

              this.$router.push('/home/front')
            }else{
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          })




        }
      }
  }
  
  </script>
  
  <style scoped>
  
  @media (max-width: 800px) {
      .mybox {
          display: flex;
          flex-direction: column;
  
          justify-content: space-between;
          align-items: center;
      }
      .bgh{
        height:600px;
      }
      .yc {
          display: none;
      }
  }
  
  /* 响应式布局 */
  @media (min-width: 800px) {
      .mybox {
        display: flex;
          justify-content: space-between;
  
          /*背景色为浅灰色*/
          text-align: center;
      }
      .tip_upload{
        margin-top: 0;
        margin-bottom: 0;
      }
      .bgh{
        height:300px;
      }
      .yc {
          
      }
  }

  </style> 