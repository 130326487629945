import service from "@/utils/axios";

export default{
    login(userInfo){
        return service.get('/login',{params:userInfo});
    },
    register(userInfo){
        return service.post('/regist',userInfo);
    },
    add_msg(formdata){
        return service.post('/addCompetitions',formdata);
    },
    get_msg(){
        return service.get('/getCompetitions');
    },
    modify_msg(newInfo){
        return service.post('/updateCompetitions',newInfo);
    },
    delete_msg(id){
        return service.get('/deleteCompetitions',{params:{id}});
    },
    verify(token){
        return service.get('/verify',{params:{token}});
    },
    enter_home(token){//初始化个人信息
        return service.get('/inithome',{params:{token}});
    },

   

}