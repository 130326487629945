<template>
    <div>
      <el-container style=" border: 1px solid #eee;text-align: center;" direction="vertical">
          <el-main style="height: 800px;">
            <el-row>
              <el-col :offset="5" :span="14">
                <div style="background-color: rgb(243, 243, 243);" class="bgh">
                  <!--内容主体-->
                  <el-row>
                    <h3>修改密码</h3>
                  </el-row>
                  
                  <el-row>
                    <div class="mybox">
                      <!-- 左半 -->
                        <el-col :offset="1" :span="10">
                          <el-form :model="formInline" class="demo-form-inline" style="width: 100%;">
                              <el-form-item>
                                  <el-input v-model="formInline.name" placeholder="用户名"></el-input>
                              </el-form-item>
                              <el-form-item>
                                  <el-input v-model="formInline.password" placeholder="旧密码"  show-password></el-input>
                              </el-form-item>
                    
                          </el-form>
                        </el-col>
                        <el-col :span="2">
                          <div class="yc">
                            <div class="el-divider el-divider--vertical" style="height: 13em;"></div>
                          </div>
                          
                        </el-col>
                        <!-- 右半 -->
                        <el-col :span="11" style="margin-right: 10px;">
                            <el-form :model="formInline" status-icon :rules="rules" ref="formInline" class="demo-form-inline" style="width: 100%;">
                              <el-form-item  prop="newpwd" >
                                  <el-input v-model="formInline.newpwd" placeholder="新密码"  show-password></el-input>
                              </el-form-item>
                              <el-form-item prop="ensurepwd" >
                                  <el-input v-model="formInline.ensurepwd" placeholder="确认新密码"  show-password></el-input>
                              </el-form-item>
                    
                          </el-form>
                        </el-col>
                  </div>
                     
                  </el-row>
  
  
                  <el-row style="background-color: rgb(80, 80, 80);" >
                    <div class="mybox">
                         <!-- 左半 -->
                     <el-col :offset="1" :span="10">
                      <span style="line-height: 50px;" @click="submit('formInline')">修改密码</span>
                    </el-col>
             <!-- 右半 -->
             <el-col :offset="2" :span="11">
            
                 <span style="line-height: 50px;" @click="tologin">返回登录</span>
              
             </el-col>
                  </div>
                    
                  </el-row>
                  
                  
                  <!--主体结束-->
                </div>
              </el-col>
            </el-row>
          </el-main>       
      </el-container>
    </div>
  </template>
  
  <script>
  //import UploadCop from 'utils/UploadCop.vue'
  import user from '@/api/user' 
  export default {
  name: 'UpdatePwd',
  
  data() {
    var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.formInline.ensurepwd !== '') {
            this.$refs.formInline.validateField('checkPass');
          }
          callback();
        }
        callback();
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.formInline.newpwd) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
        callback();
      };
        return {
          formInline: {
            name: '',
            password: '',
            newpwd: '',
            ensurepwd: ''
          },
          rules: {
            newpwd: [
                { validator: validatePass, trigger: 'blur' }
            ],
            ensurepwd: [
                { validator: validatePass2, trigger: 'blur' }
            ],
        }
        }
      },
      methods: {

        check(formName){
        var flag=true
        this.$refs[formName].validate((valid,msg) => {
          console.log(valid,msg)
          if (valid) {
            flag = true
            return true
          } else {
            flag = false
            return false
          }
        });
        console.log("flag",flag)
        return flag
      },
        submit(formName){
          var res = this.check(formName)
          console.log("结果",res)
          if(this.check(formName)==true){
            console.log('submit!');
            //构建表单
            const formData = new FormData();
            for (var key in this.formInline) {
              formData.append(key, this.formInline[key]);
            }
            //发起post请求
            user.updatepwd(formData).then(res => {
              console.log(res)
              //修改密码成功
              if(res.data.code == 200){
                this.$message({
                  message: '密码修改成功',
                  type: 'success'
                })
                this.$router.push('/')
              }
              //修改失败
              else{
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
            }); 
          }
          else{
            console.log('error submit!!');
            this.$message({
            message: '请检查输入信息！',
            type: 'error'
            });
          }
        },
        tologin(){
            this.$router.push('/')
        }
      }
  }
  
  </script>
  
  <style scoped>
  
  @media (max-width: 800px) {
      .mybox {
          display: flex;
          flex-direction: column;
  
          justify-content: space-between;
          align-items: center;
      }
      .bgh{
        height:600px;
      }
      .yc {
          display: none;
      }
  }
  
  /* 响应式布局 */
  @media (min-width: 800px) {
      .mybox {
        display: flex;
          justify-content: space-between;
  
          /*背景色为浅灰色*/
          text-align: center;
      }
      .tip_upload{
        margin-top: 0;
        margin-bottom: 0;
      }
      .bgh{
        height:300px;
      }
 
  }

  </style>