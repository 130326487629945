<template>
  <el-card style="width:100%;height: 100%;">

    <el-row>
        <el-col :span="12">
            <div style="text-align: left;">
                <el-button plain type="primary" size="mini" @click="dialogFormVisible = true" style="height: 40px"><i class="
el-icon-circle-plus"> </i> 新增</el-button>
            </div>
            
        </el-col>
        <el-col :span="12">
            <el-col :span="14" :offset="4">
                <div style="text-align: left;">
                    <el-input v-model="input" placeholder="请输入内容"  style="height: 100%;"></el-input>
                </div></el-col>
            <el-col :span="6"><div style="text-align: left;">
                <el-button size="mini" type="primary" style="height: 40px">搜索</el-button>
            </div></el-col>
        </el-col>
    </el-row>


    <el-row>
        <el-table
          :data="filterData.slice((currentPage-1)*pagesize,currentPage*pagesize)"
          style="width: 100%">
          <el-table-column
            prop="id"
            label="id"
            width="100">
          </el-table-column>
          <el-table-column
            prop="time"
            label="time"
            width="140">
          </el-table-column>
          <el-table-column
            prop="content"
            label="content"
            width="80">
          </el-table-column>
          <el-table-column
            prop="type"
            label="type"
            width="80">
          </el-table-column>
          <el-table-column
            label="操作"
            width="200">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.$index,scope.row)">修改</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
           
          </el-table-column>
        </el-table>
    </el-row>
    <p style="height:100px"></p>

    <el-row>
      <el-pagination 
     @size-change="handleSizeChange"
     @current-change="handleCurrentChange"
     :current-page="currentPage"
     :page-sizes="[5,3,2]" 
     :page-size="pagesize"
     layout="total, sizes, prev, pager, next, jumper"
     :total="filterData.length">
   </el-pagination>
    </el-row>

    <!-- 新增模态框 -->
    <el-dialog title="新增条目" :visible.sync="dialogFormVisible" center>
      <el-form :model="form">
        <el-form-item label="日期" label-width="80px">
          <el-col :span="15">
            <el-date-picker
                v-model="form.time"
                type="date"
                placeholder="年/月/日"
                value-format="yyyy-MM-dd yyMMdd"
                style="width: 100%;">
            </el-date-picker>
            
          </el-col>  
        </el-form-item>
         <!-- <el-form-item label="编号" :label-width="formLabelWidth">
          <el-col :span="15">
             <el-input v-model="form.id" autocomplete="off"></el-input>
             <el-button @click="dialogFormVisible = false">生成编号</el-button>
          </el-col>
        </el-form-item> -->

         <el-form-item label="所属" :label-width="formLabelWidth">
          <el-col :span="15">
            <el-input v-model="form.type" autocomplete="off"></el-input>
          </el-col>
        </el-form-item>

         <el-form-item label="简述" :label-width="formLabelWidth">
          <el-col :span="15">
            <el-input v-model="form.content" autocomplete="off"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="文件" :label-width="formLabelWidth">
          <el-col :span="15">
            <el-upload
              class="upload-demo"
              action="#"
              :auto-upload="false"
              :limit="1"
              :on-change="handleChange"
              :file-list="fileList"
              >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传pdf文件，且不超过500kb</div>
            </el-upload>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitpdf">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改模态框 -->
    <el-dialog title="修改条目" :visible.sync="dialogEditVisible" center>
      <el-form :model="form">
        <el-form-item label="日期" label-width="80px">
          <el-col :span="15">
            <el-date-picker
                v-model="form.time"
                type="date"
                placeholder="年/月/日"
                value-format="yyyy-MM-dd"
                style="width: 100%;">
            </el-date-picker>
            
          </el-col>
          
        </el-form-item>
        <el-form-item label="所属" :label-width="formLabelWidth">
          <el-col :span="15">
            <el-input v-model="form.type" autocomplete="off"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="简述" :label-width="formLabelWidth">
          <el-col :span="15">
            <el-input v-model="form.content" autocomplete="off"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogEditVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit_modification">确 定</el-button>
      </div>
    </el-dialog>

  </el-card>
   
  


  </template>
  
  <script>

import user from '@/api/user' 

  export default {
      name:'UserView',
      data(){
          return{
            dialogFormVisible:false, //新增用户模态框的可见性
            dialogEditVisible:false, //修改用户模态框的可见性
            formLabelWidth:'80px', //表单标签的宽度
            fileList: [], //文件上传列表
            cur_id:'',//当前正在修改的id
            form:{
              time:'',
              content:'',
              type:'',
              pdf:false,
            },
            currentPage: 1,
            currentIndex: '',
            pagesize: 5, //每页显示多少条 pagesizes数组是可以动态改变每页显示多少条
            visible:false,
            input:'', //搜索关键词
            tableData: [{
              id:'24013101',
              time: '2024-01-31',
              content: '计算机设计大赛',
              type: '本科生院'
            }, {
              id:'24013102',
              time: '2024-01-31',
              content: '计算机设计大赛',
              type: '本科生院'
            }],
          }
      },
      computed:{
        //搜索功能
        filterData:{
          get(){
            if(this.input == ''){
              return this.tableData
            }
            else{
              return this.tableData.filter(item=>{
                return item.content.indexOf(this.input) != -1
              })
            }
          },
          set(newValue){
            this.$set(this.tableData, newValue);
          }
         
        },
      },
      methods:{
        handleChange(file, fileList) {//文件上传时触发
          //console.log(this.form.time)
          console.log("this.fileList:",this.fileList)
          console.log(file, fileList);
          this.form.pdf = file.raw //获取文件二进制流
        },

        submitpdf(){//发送请求，新增条目
          const formData = new FormData();
          for (var key in this.form) {
            formData.append(key, this.form[key]);
            
          }
          user.add_msg(formData).then(res=>{
            console.log(res.data)
            if(res.data.code == 200){
              this.$message({
                type: 'success',
                message: '添加成功!'
              });
              this.dialogFormVisible = false
              //console.log('提交按钮点击后this.fileList:',this.fileList)
              this.getdata()//重新获取数据
              //表单清空
              this.form.content=''
              this.form.type=''
              this.form.time=''
              this.form.pdf=''
              this.fileList = []//文件列表清空
            }
            else{
              this.$message({
                type: 'error',
                message: '添加失败!'
              });
            }
          })
        },

        submit_modification(){//发送请求，提交修改
          const formData = new FormData();
          for (var key in this.form) {
            formData.append(key, this.form[key]);
          }
          formData.append('id',this.cur_id)
          user.modify_msg(formData).then(res=>{
            console.log(res.data)
            if(res.data.code == 200){
              this.$message({
                type: 'success',
                message: '修改成功!'
              });
              this.dialogEditVisible = false
              this.getdata()//重新获取数据
            }
            else{
              this.$message({
                type: 'error',
                message: '修改失败!'
              });
            }
          })
        },
       
        getdata(){//获取数据
          user.get_msg().then(res=>{
            if (res.data.code == 200) {
              console.log(res.data.data)
              this.tableData = res.data.data
              this.$message.success(res.data.msg)
            }
            else{
              this.$message.error(res.data.msg)
            }
          })
        },
        
        handleSizeChange(val) {//分页大小改变
          this.pagesize = val;
          console.log(`每页 ${val} 条`);
        },

        handleCurrentChange(val) {//当前页面
          this.currentPage= val
          console.log(`当前 ${val} 页`);
        },

        handleEdit(index, row) {//点击修改按钮

          console.log(index, row);//row是当前行的数据，包括没有显示的数据（如id）
          this.dialogEditVisible = true
          //将当前行的数据赋值给form，令修改模态框中的表单显示当前行的数据
          this.form.time = row.time
          this.form.content = row.content
          this.form.type = row.type
          this.cur_id = row.id
          console.log('form',this.form)
        },

        handleDelete(index, row) {//点击删除按钮
          console.log(index, row);
          this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {

            user.delete_msg(row.id).then(res=>{
              console.log(res.data.msg)
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                //this.tableData.splice(index,1)//从第index号开始，删除1个
                this.getdata()
              }
              else{
                this.$message({
                  type: 'error',
                  message: '删除失败!'
                });
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });          
          });
        }
      },
      mounted(){
        this.getdata()
      }
  }
  </script>
  
  <style>
  
  </style>