<template>
  <el-container :class="{theme1:isblue,theme2:!isblue}">
  <el-aside width="200px" class="isshow">
    <el-row>
        <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
      :background-color = color
      @open="handleOpen"
      @select="handleSelect"
      @close="handleClose">
      <el-menu-item index="0">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>功能菜单</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="1-1">比赛通知管理</el-menu-item>
          <el-menu-item index="1-2">第二课堂活动管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <i></i>
          <span>主题切换</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="2-1">蓝色</el-menu-item>
          <el-menu-item index="2-2">白色</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
     
    </el-menu>
    </el-row>
  </el-aside>
  <el-container>
    <el-header>
       <el-col :span="6" :offset="18">
        <el-dropdown @command="handleCommand"> 
          <span class="el-dropdown-link">
            {{username}} 用户<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="calendar">日历</el-dropdown-item>
            <el-dropdown-item command="info">个人信息</el-dropdown-item>
            <el-dropdown-item command="exit">退出</el-dropdown-item>
            
          </el-dropdown-menu>
        </el-dropdown>
        </el-col>
       
    </el-header>
    <el-main>
        <router-view @updatename="updatename" :show_calendar="show_calendar"></router-view>
    </el-main>
  </el-container>
</el-container>
</template>

<script>
//import user from '@/api/user' 
export default {
    name: 'HomeView',
    components: {
    },
    data(){
        return{
          show_calendar:false,
            color: '#edf6ff',
            imgURL:'',
            isblue: true,
            username: 'admin'
           
        }
    },
    // mounted(){
    //   var token = localStorage.getItem('token')
    //     user.enter_home(token).then(res=>{
    //         console.log(res)
    //         this.imgURL = res.data.data.avatar
    //         this.username = res.data.data.name
    //     })
    // },
    methods: {
      exitlogin(){
        localStorage.removeItem('token')
        this.$router.push('/',()=>{}, ()=>{})
      },
      handleCommand(command) {
        if(command == 'exit'){
          this.exitlogin()
        }
        if(command == 'calendar'){
          this.show_calendar=true
        }
        if(command == 'info'){
          this.show_calendar=false
        }
      },
      updatename(name){//接收frontview传来的用户名
        this.username = name
      },
        handleSelect(index,path){
            console.log(index,path)
            if(index=='2-1'){
                this.color= '#edf6ff'
                this.isblue=  true
            }
            else if(index=='2-2'){
                this.color= '#f1eeee'
                this.isblue=  false
            }
            if(index=='1-1'){
                this.$router.push('/home/user',()=>{}, ()=>{})
            }
            if(index=='0'){
                this.$router.push('/home/front',()=>{}, ()=>{})
            }
    
            
        },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      }
    },
   
}
</script>

<style scoped>
.el-aside::-webkit-scrollbar {
display: none;
}

.theme1{
  background-color: #edf6ff;
  /* #4288de */
}

.theme2{
  background-color: #f1eeee;
  /* #4288de */
}

@media (max-width: 800px) {
    .isshow{
        display: none;
    }
  
}

/* 响应式布局 */
@media (min-width: 800px) {
   
}
</style>